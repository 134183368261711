// TODO: extract this file to nfelib.core

export enum LegacyStorageKey {
    // These storage keys only used on GUI 1.x
    StorageProfile = 'storage-profile',
}

export enum StorageKey {
    SessionContinuity = 'nebula://session/continuity',
}

export const RETAIN_STORAGE_KEYS = [
    // page continuity
    StorageKey.SessionContinuity,
];

export interface StorageEngine {
    getItem<T = string>(key: string, defaults?: T): T | string | null;
    setItem<T = string>(key: string, value: T): T | string | null;
    removeItem<T = string>(key: string): T | string | null;
    keys(): string[];
    clear(...retainKeys: string[]): void;
}

class BrowserSessionStorage implements StorageEngine {
    private prefixPattern: string;
    private storage: Storage;

    constructor(prefix?: string) {
        this.storage = window.sessionStorage;
        this.prefixPattern = prefix ? `${prefix}://` : null;
    }

    private evalStorageKey(key: string): string {
        return this.prefixPattern == null || key.startsWith(this.prefixPattern) ? key : `${this.prefixPattern}${key}`;
    }

    private hasPrefix(key: string): boolean {
        return this.prefixPattern == null || key.startsWith(this.prefixPattern);
    }

    getItem<T = string>(key: string, defaults?: T): T | string | null {
        const ret = this.storage.getItem(this.evalStorageKey(key));
        if (ret) {
            try {
                return JSON.parse(ret) as T;
            } catch (err) {
                // no error
            }
        }
        if (ret == null && defaults !== null) {
            return defaults as T;
        }
        return ret;
    }

    setItem<T = string>(key: string, value: T): T | string | null {
        let ok = false;
        try {
            this.storage.setItem(this.evalStorageKey(key), JSON.stringify(value));
            ok = true;
        } catch (err) {
            // no error
        }
        return ok ? this.getItem<T>(key) : null;
    }

    removeItem<T = string>(key: string): T | string | null {
        const ret = this.getItem<T>(key);
        try {
            this.storage.removeItem(this.evalStorageKey(key));
        } catch (err) {
            // no error
        }
        return ret;
    }

    keys(): string[] {
        let keys = [];
        try {
            keys = Object.keys(this.storage).filter((k) => this.hasPrefix(k));
        } catch (err) {
            // no error
        }
        return keys;
    }

    clear(...retain_keys: string[]): void {
        const ignoreKeys = retain_keys.map((k) => this.evalStorageKey(k));
        try {
            this.keys()
                .filter((k) => !ignoreKeys.includes(k))
                .map((k) => {
                    console.info('delete key', k);
                    this.removeItem(k);
                });
        } catch (err) {
            // no error
        }
    }
}

class MemoryStorage implements StorageEngine {
    private storage: {[key: string]: string} = {};

    constructor() {}

    getItem<T>(key: string, defaults?: T): T | string | null {
        const ret = this.storage[key];
        if (ret) {
            try {
                return JSON.parse(ret) as T;
            } catch (err) {
                // no error
            }
        }
        if (ret == null && defaults !== null) {
            return defaults;
        }
        return ret;
    }

    setItem<T>(key: string, value: T): T | string | null {
        this.storage[key] = JSON.stringify(value);
        return this.storage[key] || null;
    }

    removeItem<T>(key: string): T | string | null {
        const ret = this.getItem<T>(key);
        try {
            delete this.storage[key]; // eslint-disable-line @typescript-eslint/no-dynamic-delete
        } catch (err) {
            // no error
        }
        return ret;
    }

    keys(): string[] {
        return Object.keys(this.storage);
    }

    clear(): void {
        this.storage = {};
    }
}

export function createStorageArea(prefix?: string): StorageEngine {
    try {
        return new BrowserSessionStorage(prefix);
    } catch (err) {
        return new MemoryStorage();
    }
}
