import jQuery from 'jquery';

export const States = {
    UNDETERMINED: 'UNDETERMINED',
    NORMAL: 'NORMAL',
    NORMAL_NO_ORG: 'NORMAL_NO_ORG',
    NORMAL_WITH_ORG: 'NORMAL_WITH_ORG',
    MAINTAINING: 'MAINTAINING',
    OUTAGE: 'OUTAGE',
};

export const ajaxDefaultParams = {
    type: 'GET',
    dataType: 'json',
    headers: null,
    contentType: 'application/json; charset=UTF-8',
    cache: false,
    crossDomain: true,
    data: '',
    xhrFields: {withCredentials: true},
};

export const ajaxCall = function(params) {
    let queryParams = Object.assign({}, ajaxDefaultParams, params);
    if (queryParams.data) {
        queryParams.data = JSON.stringify(queryParams.data);
    }
    let d = jQuery.Deferred();
    jQuery
        .ajax(queryParams)
        .done(function(data) {
            console.info(`ajax request - ${queryParams.url}`);
            d.resolve(data);
        })
        .fail(function(jqXHR, textStatus) {
            let reason = '';
            if (jqXHR.status === 0) {
                reason = ' Not connect. Verify Network.';
            } else if (jqXHR.status === 404) {
                reason = ' Requested page not found. [404]';
            } else if (jqXHR.status === 500) {
                reason = ' Internal Server Error [500].';
            } else if (textStatus === 'parsererror') {
                reason = ' Requested JSON parse failed.';
            } else if (textStatus === 'timeout') {
                reason = ' Time out error.';
            } else if (textStatus === 'abort') {
                reason = ' Ajax request aborted.';
            } else {
                reason = ' Uncaught Error.' + jqXHR.responseText;
            }
            let msg = `${params.errmsg}${reason}`;
            console.info(msg);
            d.reject(msg);
        });
    return d.promise();
};

export const setSessionStorage = function(key, value) {
    let storage = sessionStorage ? sessionStorage : null;
    if (storage) {
        storage.setItem(key, value);
    }
};

export const getSessionStorage = function(key) {
    let storage = sessionStorage ? sessionStorage : null;
    return storage ? storage.getItem(key) : undefined;
};

export default {
    ajaxCall: ajaxCall,
    setSessionStorage: setSessionStorage,
    getSessionStorage: getSessionStorage,
    States: States,
};
