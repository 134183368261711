import jQuery from 'jquery';
import {ajaxCall, setSessionStorage, getSessionStorage, States} from './sysstat_helper';

const stateCtl = {
    backend: process.env.SDWAN_HOST.domain,
    maintenance: process.env.SDWAN_HOST.maintenance,
    maintaining: jQuery.Deferred(),
    uri: {
        auth: '/nebula/v3/account/auth',
        orgList: '/nebula/v3/organization',
        logout: '/nebula/v3/logout',
        main: '/web/index.html',
    },
    _check_maintain: function () {
        let resp = jQuery.Deferred();
        jQuery
            .ajax({
                url: this.maintenance,
                type: 'GET',
                dataType: 'text',
                cache: false,
            })
            .done(function () {
                console.info('[sdwan_check_maintenance] - success');
                resp.resolve(States.MAINTAINING);
            })
            .fail(function () {
                console.info('[sdwan_check_maintenance] - not found');
                resp.resolve(null);
            });
        return resp.promise();
    },
    _login: function (accessToken, mzcUserInfo) {
        let resp = ajaxCall({
            url: `${this.backend}${this.uri.auth}`,
            type: 'POST',
            data: {user_info: mzcUserInfo, zyxel_oauth_token: accessToken},
            errmsg: '[sdwan_auth_login] get sdwan auth token failed.',
        });
        return resp;
    },
    _logout: function (authtoken) {
        if (!authtoken) {
            return;
        }
        let resp = ajaxCall({
            url: `${this.backend}${this.uri.logout}`,
            data: {},
            headers: {
                'x-auth-token-cubs': authtoken,
            },
        });
        return resp;
    },
    _check_orgs: function (accessToken, mzcUserInfo) {
        let _d = jQuery.Deferred();
        this._login(accessToken, mzcUserInfo)
            .then((data) => {
                setSessionStorage('x-auth-token-cubs', data.body.authtoken);
                setSessionStorage('nebula://session/x-auth-token-cubs', data.body.authtoken);
                return ajaxCall({
                    url: `${this.backend}${this.uri.orgList}`,
                    type: 'GET',
                    headers: {'x-auth-token-cubs': data.body.authtoken},
                    errmsg: '[sdwan_get_org_list] get nebula auth token failed.',
                });
            })
            .then(function (data) {
                _d.resolve(data.body.length ? States.NORMAL_WITH_ORG : States.NORMAL_NO_ORG);
            })
            .fail(function () {
                _d.resolve(States.NORMAL);
            });
        return _d.promise();
    },
    isMaintain: function (forceStatus) {
        return jQuery
            .when(forceStatus)
            .then((status) => {
                // check maintain
                return status !== null ? jQuery.when(status) : this._check_maintain();
            })
            .then((status) => {
                this.maintaining.resolve(status);
                return status;
            });
    },
    check: function (accessToken, mzcUserInfo) {
        return this.maintaining.promise().then((status) => {
            // check orgs
            return status !== null ? jQuery.when(status) : this._check_orgs(accessToken, mzcUserInfo);
        });
    },
    enter: function () {
        console.info('goto sdwan');
        location.href = `${this.backend}${this.uri.main}`;
    },
    logout: function () {
        const token = (getSessionStorage('x-auth-token') || '').replace(/"/g, '');
        let _d = jQuery.Deferred();
        if (token) {
            this._logout(token).always(function () {
                _d.resolve();
            });
        } else {
            _d.resolve();
        }
        return _d.promise();
    },
};

export default stateCtl;
