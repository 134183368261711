import jQuery from 'jquery';

import ncc_stat from './sysstat_ncc';
import sdwan_stat from './sysstat_sdwan';
import {States} from './sysstat_helper';

const stateCtl = {
    status: States,
    queryParams: {},
    hashParams: {},
    _decodeUrlParams: function(str) {
        if (!str) {
            return {};
        }
        let jsonBody = decodeURI(str)
            .replace(/"/g, '\\"')
            .replace(/&/g, '","')
            .replace(/=/g, '":"');
        return JSON.parse(`{"${jsonBody}"}`);
    },
    init: function() {
        const location = window && window.location ? window.location : {search: '', hash: ''};
        this.queryParams = this._decodeUrlParams(location.search.substring(1));
        this.hashParams = this._decodeUrlParams(location.hash.substring(1));
    },
    isMaintain: function() {
        return jQuery.when.apply(this, [
            sdwan_stat.isMaintain(this.queryParams.sdwan_maintenance ? States.MAINTAINING : null),
            ncc_stat.isMaintain(this.queryParams.nebula_maintenance ? States.MAINTAINING : null),
        ]);
    },
    check: function(mzcUserInfo) {
        let accessToken = this.hashParams.access_token;
        return jQuery.when.apply(this, [
            sdwan_stat.check(accessToken, mzcUserInfo),
            ncc_stat.check(accessToken, mzcUserInfo),
        ]);
    },
    jumpTo: function(target) {
        switch (target) {
            case 'NCC':
                ncc_stat.enter();
                break;
            case 'SDWAN':
                sdwan_stat.enter();
                break;
        }
    },
    logout: function() {
        return jQuery.when.apply(this, [sdwan_stat.logout(), ncc_stat.logout(this.queryParams.x_auth_token)]);
    },
};

export default stateCtl;
