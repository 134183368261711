interface TokenStorage {
    token: string | null;
    setToken(token: string | null): void;
    clearToken(): void;
}

class CookieTokenStorage implements TokenStorage {
    private key = 'zyxel_oauth_token';
    private cookiePattern = /(?:(?:^|.*;\s*)zyxel_oauth_token\s*=\s*([^;]*).*$)|^.*$/;

    constructor() {}

    get token(): string | null {
        const token = document.cookie.replace(this.cookiePattern, '$1');
        return token.length ? token : null;
    }

    setToken(token: string | null): void {
        if (!token) {
            return;
        }
        document.cookie = `${this.key}=${token};secure`;
    }

    clearToken(): void {
        document.cookie = `${this.key}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }
}

class LocalTokenStorage implements TokenStorage {
    private key = 'zyxel_oauth_token';
    private storage: Storage = null;

    constructor() {
        this.storage = window.localStorage;
        this.storage.getItem('check-storage-available');
    }

    get token(): string | null {
        return this.storage.getItem(this.key);
    }

    setToken(token: string | null): void {
        if (!token) {
            return;
        }
        this.storage.setItem(this.key, token);
    }

    clearToken(): void {
        this.storage.removeItem(this.key);
    }
}

let storage: TokenStorage = null;

function getAvailableStorage(): TokenStorage {
    if (!storage) {
        try {
            storage = new LocalTokenStorage();
        } catch (e) {
            storage = new CookieTokenStorage();
        }
    }
    return storage;
}

const URL_HASH_PATTERN = /#access_token=(.*?)(&)/i;

function retrieveFromUrlHash(): string | null {
    const results = URL_HASH_PATTERN.exec(window.location.hash);
    return results && results.length ? decodeURIComponent(results[1].replace(/\+/g, ' ')) : null;
}

export function getToken(): string | null {
    return getAvailableStorage().token;
}

export function setToken(token: string): void {
    getAvailableStorage().setToken(token);
}

export function cleanUp(): void {
    getAvailableStorage().clearToken();
}

export function retrieve(): string {
    let token = retrieveFromUrlHash();
    if (token) {
        getAvailableStorage().setToken(token);
    } else {
        token = getAvailableStorage().token;
    }
    return token;
}
