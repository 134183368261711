import {HttpDataType, HttpMethod, HttpRequest, MzcClientOptions} from './definition';

const DEFAULT_REQUEST_TIMEOUT = 30 * 1000;

function fetchRequest(input: RequestInfo, init?: RequestInit, timeout?: number): Promise<Response> {
    let isTimeout = false;
    return new Promise<Response>((resolve, reject) => {
        const timer = setTimeout(() => {
            isTimeout = true;
            reject(new Error('timeout'));
        }, timeout || DEFAULT_REQUEST_TIMEOUT);
        fetch(input, init)
            .then((resp) => {
                if (!isTimeout) {
                    resolve(resp);
                }
            })
            .catch((reason) => {
                if (!isTimeout) {
                    reject(reason);
                }
            })
            .finally(() => {
                clearTimeout(timer);
            });
    });
}

export enum MzcOauthError {
    INITIALIZED = 'Already initialized.',
    EMPTY_APPID = 'App ID is required.',
    EMPTY_REDIRECT_URI = 'Redirect URI is required.',
}

export class MzcOAuthClient {
    private _host = 'https://beta.account.zyxel.com/';
    private _appKey: string;
    private _loginRedirectUri: string;
    private _logoutRedirectUri: string;

    constructor(options: MzcClientOptions) {
        if (this._appKey) {
            throw MzcOauthError.INITIALIZED;
        }
        if (!options.appKey) {
            throw MzcOauthError.EMPTY_APPID;
        }
        if (!options.loginRedirectUri) {
            throw MzcOauthError.EMPTY_REDIRECT_URI;
        }

        this._appKey = options.appKey;
        if (options.host) {
            this._host = options.host; // Override the default OAuth server host
        }
        this._loginRedirectUri = options.loginRedirectUri;
        this._logoutRedirectUri = options.logoutRedirectUri;
    }

    private get appKey(): string {
        if (!this._appKey) {
            throw MzcOauthError.EMPTY_APPID;
        }
        return this._appKey;
    }

    private get loginRedirectUri(): string {
        if (!this._loginRedirectUri) {
            throw MzcOauthError.EMPTY_REDIRECT_URI;
        }
        return this._loginRedirectUri;
    }

    private prepareRequestUrl(host: string, request: HttpRequest): string {
        let url = `${host}${request.uri}`;
        if (request.params != null) {
            url +=
                '?' +
                Object.entries(request.params)
                    .map(([key, value]) => {
                        return `${key}=${encodeURIComponent(value)}`;
                    })
                    .join('&');
        }
        return url;
    }

    private prepareRequestPayload(session_token: string, request: HttpRequest): RequestInit {
        const payload: RequestInit = {
            headers: {Authorization: `Bearer ${session_token}`}, // http://tools.ietf.org/html/rfc6750#page-5
            method: request.method || HttpMethod.GET,
        };
        if (payload.method !== HttpMethod.GET && request.body !== undefined) {
            if (request.body === null) {
                payload.body = null;
            } else if (typeof request.body === 'number') {
                payload.body = request.body.toString();
            } else if (typeof request.body === 'string') {
                payload.body = request.body;
            } else {
                payload.body = JSON.stringify(request.body);
            }
        }
        return payload;
    }

    toLogin(): void {
        window.location.href = `${this._host}oauth2/authorize?response_type=token&client_id=${encodeURIComponent(
            this.appKey,
        )}&redirect_uri=${encodeURIComponent(this.loginRedirectUri)}`;
    }

    toLogout(session_token: string): void {
        let uri = `${this._host}oauth2/logout`;
        if (this._logoutRedirectUri) {
            uri = `${uri}?access_token=${session_token}&client_id=${encodeURIComponent(
                this.appKey,
            )}&logout_redirect_uri=${encodeURIComponent(this._logoutRedirectUri)}`;
        }
        window.location.href = uri;
    }

    async request<R>(session_token: string, request: HttpRequest): Promise<R> {
        try {
            const resp = await fetchRequest(
                this.prepareRequestUrl(this._host, request),
                this.prepareRequestPayload(session_token, request),
                request.timeout,
            );
            return request.responseType === HttpDataType.JSON ? resp.json() : resp.text();
        } catch (error) {
            throw [error.status, error.statusText];
        }
    }
}
