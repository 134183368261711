import {MzcOAuthClient, MzcSessionManager} from './mzc';
import {createStorageArea, LegacyStorageKey, RETAIN_STORAGE_KEYS, StorageEngine} from './storage';

const location = window.location;

export class MzcOAuthPortal {
    private session: MzcSessionManager;
    private storage: StorageEngine;

    private _isAuthorized = false;
    private _userInfo: string = null;

    constructor(app_key: string, login_redirect_uri: string, logout_redirect_uri: string, host: string) {
        this.storage = createStorageArea();
        const client = new MzcOAuthClient({
            host: host || 'https://beta.account.zyxel.com/',
            appKey: app_key || 'empty-mzc-oauth-app-key',
            loginRedirectUri: login_redirect_uri || `${location.protocol}//${location.host}/cc/index.html`,
            logoutRedirectUri: logout_redirect_uri || `${location.protocol}//${location.host}/`,
        });
        this.session = new MzcSessionManager(client);
    }

    get $isAuthorized(): boolean {
        return this._isAuthorized;
    }

    get $userInfo(): string {
        return this._userInfo;
    }

    async init(): Promise<boolean> {
        try {
            await this.session.authorize();
            this._isAuthorized = true;
        } catch (error) {
            console.error('MZC not authorized');
            this._isAuthorized = false;
        }
        return this._isAuthorized;
    }

    restoreToken(oauth_token: string): void {
        this.session.setAuthToken(oauth_token);
    }

    restoreCookie(oauth_token: string): void {
        // deprecated
        this.restoreToken(oauth_token);
    }

    login(): void {
        return this.session.login();
    }

    async checkAuthStatus(storage_key: string): Promise<boolean> {
        const storageKey = storage_key || LegacyStorageKey.StorageProfile;
        const encrypted =
            sessionStorage[storageKey] &&
            JSON.parse(sessionStorage[storageKey]) &&
            JSON.parse(sessionStorage[storageKey]).info;
        this._isAuthorized = encrypted !== undefined && storageKey !== null;
        if (this._isAuthorized) {
            return this._isAuthorized;
        }
        this._isAuthorized = await this.session.checkAuthToken();
        return this._isAuthorized;
    }

    logout(): void {
        this.cleanUp([]);
        if (this._isAuthorized) {
            this.session.logout();
        } else {
            location.href = '/'; // redirect directly
        }
    }

    async getMyInfo(): Promise<string> {
        if (!this._isAuthorized) {
            throw 'not login';
        }
        try {
            const encrypted = await this.session.getMyInfo();
            this._userInfo = encrypted.result;
        } catch ([err, msg]) {
            console.error(err, msg);
            this._userInfo = null;
            throw `unable to get user info: ${err}, ${msg}`;
        }
        return this._userInfo;
    }

    cleanUp(retain_storages: string[] = []): void {
        this.storage.clear(...retain_storages, ...RETAIN_STORAGE_KEYS);
    }
}
