import * as MzcUserToken from './user-token';
import {MzcOAuthClient} from './client';
import {HttpDataType, HttpMethod, MzcApi} from './definition';

export enum MzcSessionError {
    UNAUTHORIZED = 'Unauthorized.',
}

export class MzcSessionManager {
    private _authorized = false;

    constructor(private client: MzcOAuthClient) {}

    private clearToken(): void {
        MzcUserToken.cleanUp();
        this._authorized = false;
    }

    get token(): string {
        const token = MzcUserToken.getToken();
        if (!token) {
            throw MzcSessionError.UNAUTHORIZED;
        }
        return token;
    }

    login(): void {
        if (this._authorized) {
            return;
        }
        this.client.toLogin();
    }

    async authorize(): Promise<void> {
        if (this._authorized) {
            return;
        }
        const token = MzcUserToken.retrieve();
        try {
            await this.getMyInfo(token);
            this._authorized = true;
        } catch (error) {
            this.clearToken();
            throw error;
        }
        return;
    }

    async checkAuthToken(): Promise<boolean> {
        if (this._authorized) {
            return this._authorized;
        }
        const token = MzcUserToken.retrieve();
        try {
            const data: any = await this.client.request(this.token, {
                uri: MzcApi.AuthStatus,
                method: HttpMethod.GET,
                body: {token: token},
                responseType: HttpDataType.JSON,
                timeout: 1500,
            });
            if (data.result === true) {
                this._authorized = true;
            } else {
                this.clearToken();
            }
        } catch (error) {
            console.warn('Error while getting authorization status, maybe unauthorized?');
            console.warn(`${error.status}: ${error.statusText}`);
            this.clearToken();
        }
        return this._authorized;
    }

    async getMyInfo(token?: string): Promise<any> {
        return await this.client.request(token || this.token, {
            uri: MzcApi.UserInfo,
            params: {trigger: 'my_info', tragger_name: 'my_info'},
            responseType: HttpDataType.JSON,
        });
    }

    async getMyDevices(): Promise<any> {
        return await this.client.request(this.token, {
            uri: MzcApi.UserDevices,
            params: {trigger: 'my_devices', trigger_name: 'my_devices'},
            responseType: HttpDataType.JSON,
        });
    }

    async getMyDevicesSummary(): Promise<any> {
        return await this.client.request(this.token, {
            uri: MzcApi.UserDeviceSummary,
            params: {trigger: 'my_summary', trigger_name: 'my_summary'},
            responseType: HttpDataType.JSON,
        });
    }

    async getMyReminderStatus(): Promise<any> {
        return await this.client.request(this.token, {
            uri: MzcApi.UserReminderStatus,
            params: {trigger: 'my_reminder_status', trigger_name: 'my_reminder_status'},
            responseType: HttpDataType.JSON,
        });
    }

    setAuthToken(token: string): void {
        MzcUserToken.setToken(token);
    }

    async logout(): Promise<void> {
        const token = this.token;
        this.clearToken();
        this.client.toLogout(token);
    }
}
